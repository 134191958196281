/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
import React from 'react'
import Grid from '@mui/material/Grid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ChatIcon from '@mui/icons-material/Chat';
import TuneIcon from '@mui/icons-material/Tune';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import TextField from '@mui/material/TextField';
import styles from "../../styles/asset.module.css";
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
// import { setDocumentNumber, setRevisionDate, setRevisionNumber } from '../../redux/features/template/templateSclice';

function EptwTemplateDetails({
  addCustomSectionHandler,
  removeCustomSectionHandler,
  handelCutomSectionChange,
  setRevisionNumber,
  setRevisionDate,
  setDocumentNumber,
  customSection,
  documentNumber,
  revisionDate,
  revisionNumber,
  addnewFeildHandler
}) {
  const [initialValues,setInitialValues]=React.useState({documentNumber,revisionNumber,revisionDate})
  const { user } = useSelector((state) => state.user);
  const date = revisionDate?.split('T')[0]||"";
  return (

    <div className={styles.assetForm}>
      <div className={styles.assetInfo}>
        <>
          <div className='Checkboxes'>
                  <Grid key={"static-info"} container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        label="Document Number"
                        variant="outlined"
                        fullWidth
                        style={{ fontSize: '60px' }}
                        margin="normal"
                        disabled={initialValues.documentNumber!== "" && user?.role !== "Administrator"}
                        onChange={(e) => {
                          setDocumentNumber(e.target.value);
                        }}
                        value={documentNumber}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Permit Number"
                        variant="outlined"
                        fullWidth
                        style={{ fontSize: '60px' }}
                        margin="normal"
                        disabled={initialValues.documentNumber!== "" && user?.role !== "Administrator"}
                        // onChange={(e) => {
                        //   setDocumentNumber(e.target.value);
                        // }}
                        value={'Auto Generated Running Number'}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Revision Number"
                        disabled={initialValues.revisionNumber!== "" && user?.role !== "Administrator"}
                        variant="outlined"
                        fullWidth
                        style={{ height: '60px' }}
                        margin="normal"
                        onChange={(e) => setRevisionNumber(e.target.value)}
                        value={revisionNumber !== "" ? revisionNumber : null}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Revision Date"
                        disabled={initialValues.revisionDate !== null && user?.role !== "Administrator"}
                        type="date"
                        variant="outlined"
                        fullWidth
                        style={{ height: '60px' }}
                        margin="normal"
                        onChange={(e) => setRevisionDate(e.target.value)}
                        value={date|| null}
                        InputLabelProps={{
                          shrink: true, // Hide the default placeholder text
                        }}
                      />
                    </Grid>
                  </Grid>
            {
              customSection?.length > 0 && customSection?.map((input, i) => {
                return <>
                  <div key={i} className={styles.controlsSp}>
                    <br />
                    <div className={styles.controlSp}>
                      <TextField
                        label="Section name"
                        variant="outlined" fullWidth margin="normal"
                        onChange={(e) => handelCutomSectionChange(e, i, input?.id, "title")}
                        value={input?.title}
                      />
                    </div>
                    <button className={styles.controlSpBtn} onClick={() => removeCustomSectionHandler(i, "section")}
                      type='button' title='Remove Section'>
                      <CloseSharpIcon />
                    </button>
                    {/* </div> */}
                  </div>
                  <div className='Checkboxes'>
                    {console.log("the received info",input.fields)}
                    {
                      input.fields.length > 0 && input.fields.map((item, idx) => {
                        return item.type !== "Attachment" && <div key={i} className={styles.controlsSection}>
                          <div className={styles.controlSection} >
                            <input
                              name='name'
                              placeholder="Enter form field"
                              onChange={(e) => handelCutomSectionChange(e, i, item?.id, "feild", idx)}
                              value={item.name}
                            />
                            <select name='type' value={item.type} onChange={(e) => handelCutomSectionChange(e, i, item?.id, "feild", idx)}>
                              <option value="String">✏️  Text</option>
                              {/* <option value="Number">🔢 Number</option> */}
                              <option value="Checkbox">☑️  Checkbox</option>
                              <option value="Date">⏰ Date & Time </option>
                              <option value="Signature">📑 Signature</option>
                              {/* <option value="Attachment">📂 Attachment</option> */}
                              <option value="Description">📝 Description</option>
                            </select>
                            {item.type === "Checkbox" || item.type === "Description" ? "" : <>Required? 
                            <div style={{marginLeft:5}}>
                            <input name='isRequired' type="checkbox" checked={item.isRequired} value={item.isRequired} style={{transform:'scale(1.5)'}} onChange={(e) => handelCutomSectionChange(e, i, item?.id, "feild", idx)} />
                            </div>
                            </>
                            }
                          </div>
                          <div className='flex'>
                            <button className={`${styles.controlSectionBtn} ${styles.hovering} mr-1`}
                              onClick={() => addnewFeildHandler(i, "feild", idx)} type='button'
                              title='Add Feild'>
                              <AddSharpIcon />
                            </button>
                            <button className={styles.controlSectionBtn}
                              onClick={() => removeCustomSectionHandler(i, "feild", idx)} type='button'
                              title='Remove Feild'>
                              <CloseSharpIcon />
                            </button>
                          </div>
                        </div>
                      })
                    }
                  </div>
                </>
              })
            }

            {/* addCustomInputHandler */}
            <div className={styles.actionsSection}>
              <button disabled={customSection.length === 0} onClick={() => addCustomSectionHandler("feild")} className='mr-3' type='button'>
                <AddSharpIcon />Add form field
              </button>
              <button onClick={() => addCustomSectionHandler("section")} type='button'>
                <AddSharpIcon />Add new section
              </button>
            </div>
          </div>
        </>
      </div>
    </div>
  )
}

export default EptwTemplateDetails