import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import { Button, Modal } from 'react-bootstrap/';
import moment from 'moment';
import { toast } from "react-toastify"
import { useLocation } from 'react-router-dom';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import ImageIcon from '@mui/icons-material/Image';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import DrawIcon from '@mui/icons-material/Draw';
import { useDispatch, useSelector } from 'react-redux';
import SuccessPage from '../../components/Success';
import SignatureCanvas from 'react-signature-canvas'
import { Avatar } from '@mui/material';
import {
    setWorkType,
    setAttributes,
    setStartDate,
    setEndDate,
    createEptw,
    setIdType,
    setSubcontractorName,
    setsubcontractorRepresentative,
    setContractorName,
    setContractorLastName,
    setContractorEmail,
    setContractorPhoneNumber,
    setCompanyContactNumber,
    setPassportNumber,
    setLicenseNumber,
    setGreenCardNumber,
    setMykadNumber,
    setCompanyName,
    getFillEptw,
    setDescription,
    createFillEptw,
    setSections,
    setForms,
    setHirarcSections
} from '../../redux/features/eptw/eptwSlice';
import logo from "../../assets/img/FOX-Logo3.png"
import Loader from '../../components/loader/loader';
// import TemplateReview from '../../components/Steps/templateReview';
import classes from "../../styles/asset.module.css";
import Signature from './Signature';

function ContractorView() {
    const [showSubContractor, setShowSubContractor] = React.useState(false)
    const [isShowSignModal, setIsShowSignModal] = React.useState(false);
    const [sign, setSign] = React.useState(null);
    const [signFile, setSignFile] = React.useState(null);
    const [signFilePhoto, setSignFilePhoto] = React.useState(null);
    const [signUrlImg, setSignUrlImg] = React.useState(null);
    const [signError, setSignError] = React.useState(false);
    const [theSignatureURL, setTheSignatureURL] = React.useState(null);
    const {
        contractorFirstName,
        contractorLastName,
        subcontractorName,
        subcontractorRepresentative,
        contractorEmail,
        contractorPhoneNumber,
        companyName,
        companyContactNumber,
        passportNumber,
        workType,
        attributes,
        licenseNumber,
        startDate,
        endDate,
        greenCardNumber,
        idType,
        mykadNumber,
        description,
        eptw,
        isEptwsGetLoading,
        sections,
        revisionNumber,
        revisionDate,
        forms,
        hirarcSections
    } = useSelector(state => state.eptw)
    const dispatch = useDispatch();
    const location = useLocation().pathname.split("/")
    const ptwId = location[2]
    const contractorId = location[3]
    const route = `${ptwId}/${contractorId}`
    useEffect(() => {
        dispatch(getFillEptw(route))
    }, [route])


    const convertSectionsToArray = (sections) => {
        const newSections = sections?.map(item => {
            if (item.type === "Checkbox" && item.value === null) {
                return { ...item, value: false }
            }
            return item
        })
        return newSections || []
    }

    useEffect(() => {
        dispatch(setContractorName(eptw?.contractor?.first_name || ""))
        dispatch(setContractorLastName(eptw?.contractor?.last_name || ""))
        dispatch(setSubcontractorName(eptw?.sub_contractor || ""))
        dispatch(setSubcontractorName(eptw?.sub_contractor_representative || ""))
        dispatch(setContractorEmail(eptw?.contractor?.email || ""))
        dispatch(setContractorPhoneNumber(eptw?.contractor?.phone_number || ""))
        dispatch(setWorkType(eptw?.work_type || ""))
        dispatch(setCompanyName(eptw?.contractor?.company_name || ""))
        // dispatch(setAttributes(eptw?.attributes || []))
        // dispatch(setStartDate(eptw?.start_date || ""))
        // dispatch(setEndDate(eptw?.end_date || ""))
        dispatch(setDescription(eptw?.work_description || ""))
        if (eptw?.category === "HIRAC") {
            const arr = eptw?.sections?.map((item) => {
                let f = item?.fields.map((ele) => {
                    if (ele.name === "risk_rating") {
                        const severity = item.fields.find((field) => field.name === "severity").value || 1;
                        const likelihood = item.fields.find((field) => field.name === "likelihood").value || 1;
                        return { ...ele, value: severity * likelihood };
                    } else return ele
                })
                return { ...item, fields: f }
            })
            dispatch(setHirarcSections(arr))
        } else {
            dispatch(setSections(convertSectionsToArray(eptw?.sections && eptw?.sections)))
        }
    }, [eptw])


    const handelContractorName = (e) => {
        dispatch(setContractorName(e.target.value))
    }
    const handelSubcontractorName = (e) => {
        setShowSubContractor(true)
        dispatch(setSubcontractorName(e.target.value))
    }
    const handelSubcontractorRepresentative = (e) => {
        dispatch(setsubcontractorRepresentative(e.target.value))
    }
    const handelContractorEmail = (e) => {
        dispatch(setContractorEmail(e.target.value))
    }
    const handelContractorPhoneNumber = (e) => {
        dispatch(setContractorPhoneNumber(e.target.value))
    }
    const handelCompanyName = (e) => {
        dispatch(setCompanyName(e.target.value))
    }
    const handelCompanyContactNumber = (e) => {
        dispatch(setCompanyContactNumber(e.target.value))
    }
    const handelPassportNumber = (e) => {
        dispatch(setPassportNumber(e.target.value))
    }
    const handelWorkType = (e) => {
        dispatch(setWorkType(e.target.value))
    }

    const handelSections = async (e, ele, index, idx) => {
        const items = await Promise.all(sections?.map(async (item, i) => {
            if (i === index) {
                const f = await Promise.all(item.fields.map(async (l, j) => {
                    if (idx === j) {
                        if (l?.type !== "Checkbox" && l?.type !== "Attachment") {
                            return { ...l, value: e.target.value };
                        } 
                        else {
                            return { ...l, value: !l?.value };
                        }
                    } else return l;
                }));
                return { ...item, fields: f };
            } else return item;
        }));
        dispatch(setSections((items)))
    };

    const handelLicenseNumber = (e) => {
        dispatch(setLicenseNumber(e.target.value))
    }
    const handleStartDate = (e) => {
        dispatch(setStartDate(e.target.value))
    }
    const handleEndDate = (e) => {
        dispatch(setEndDate(e.target.value))
    }
    const handleGreenCardNumber = (e) => {
        dispatch(setGreenCardNumber(e.target.value))
    }
    const handleMykadNumber = (e) => {
        dispatch(setMykadNumber(e.target.value))
    }
    const handelIdType = (e) => {
        dispatch(setIdType(e.target.value))
    }
    const handelDescription = (e) => {
        dispatch(setDescription(e.target.value))
    }

    const [rowId, setRowId] = React.useState(null);
    const [fieldId, setFieldId] = React.useState(null);
    useEffect(() => {
        if (fieldId || rowId) {
            const arr = hirarcSections.map((item, i) => {
                if (i === rowId) {
                    let f = item?.fields.map((ele) => {
                        if (ele.name === "risk_rating") {
                            const severity = item.fields.find((field) => field.name === "severity").value || 1;
                            const likelihood = item.fields.find((field) => field.name === "likelihood").value || 1;
                            return { ...ele, value: severity * likelihood };
                        } else return ele
                    })
                    return { ...item, fields: f }
                } else return item
            })
            dispatch(setHirarcSections(arr))
            setRowId(null)
            setFieldId(null)
        }
    }, [fieldId, rowId])
    const handelHirarcChange = (e, index, idx) => {
        setRowId(index)
        setFieldId(idx)
        const arr = hirarcSections.map((item, i) => {
            if (i === index) {
                const f = item?.fields.map((ele, j) => {
                    if (j === idx) {
                        return { ...ele, value: e.target.value };
                    } else return ele
                })
                return { ...item, fields: f }
            } else return item
        })
        dispatch(setHirarcSections(arr))
    }
    console.log("arr", hirarcSections);

    const handelChangeOnMain = (e) => {
        const arr = hirarcSections.map((item) => {
            if (item.title === "main") {
                const f = item?.fields.map((ele) => {
                    if (e.target.name === ele.name) {
                        return { ...ele, value: e.target.value }
                    } else return ele
                })
                return { ...item, fields: f }
            } else return item
        })
        dispatch(setHirarcSections(arr))
    }
    console.log("arraaaaaaay", hirarcSections);

    const addNewRowHandeler = () => {
        const noOfNewRow = hirarcSections.length
        const newRow = {
            "title": noOfNewRow,
            "fields": [
                { "name": "sequence_of_activity", "type": "String", "value": null },
                { "name": "potential_hazard", "type": "String", "value": null },
                { "name": "potential_imacts", "type": "String", "value": null },
                { "name": "existing_controls", "type": "String", "value": null },
                { "name": "severity", "type": "Number", "value": null },
                { "name": "likelihood", "type": "Number", "value": null },
                { "name": "risk_rating", "type": "Number", "value": null },
                { "name": "recommended_control_measures", "type": "String", "value": null },
            ]
        }
        dispatch(setHirarcSections([...hirarcSections, newRow]))
    }
    const removeRowHandeler = () => {
        const arr = [...hirarcSections]
        arr.pop()
        dispatch(setHirarcSections(arr))
    }
    function stringAvatar(name1, name2, image) {
        if (image && !image.includes('no_profile')) {
            return {
                src: image
            };
        } else {
            const initials = name1.charAt(0).toUpperCase() + name2.charAt(0).toUpperCase() || "--"
            return {
                children: initials
            };
        }
    }
    const data = {
        sub_contractor: subcontractorName,
        sub_contractor_representative: subcontractorRepresentative,
        work_description: description,
        // start_date: startDate,
        // end_date: endDate,
        contractor: {
            first_name: contractorFirstName,
            last_name: contractorLastName || "last name",
            email: contractorEmail,
            phone_number: contractorPhoneNumber,
            company_name: companyName,
            company_contact_number: companyContactNumber,
            id_type: idType,
            passport: idType === "Passport" ? {
                passport_number: passportNumber,
                license_number: licenseNumber,
                saftey_green_card_number: greenCardNumber
            } : null,
            mykad: idType === "MyKad" ? {
                mykad_number: mykadNumber,
                license_number: licenseNumber,
                saftey_green_card_number: greenCardNumber
            } : null,
            work_type: workType
        },
    }

    const date = eptw?.revision_date?.split('T')[0];
    const handelSubmit = async (e) => {
        e.preventDefault()
        if (eptw?.category !== "HIRAC") {
            let isValidSubmission = true
            for (const singleObj of sections) {
                for (const element of singleObj.fields) {
                    if (element.isRequired === true && element.value === null) {
                        toast.error(`Field ${element.name} is required`, {
                            toastId: 'permitFillError',
                        });
                        isValidSubmission = false;
                        break; // Break out of the inner loop
                    }
                }

                if (!isValidSubmission) {
                    break; // Break out of the outer loop if an invalid submission is encountered
                }
            }

            if (isValidSubmission) {
                dispatch(createFillEptw({ route, data: { ...data, sections } }))
            }
        } else {
            dispatch(createFillEptw({ route, data: { ...data, sections: hirarcSections } }))
        }

    }
    if (isEptwsGetLoading) {
        return <Loader />
    }
    if (eptw?.status === 'Submitted' || eptw?.status === 'Approved' || eptw?.status === 'Resubmitted') {
        return (
            <SuccessPage title=
                {eptw?.status === 'Submitted' ? 'Submitted'
                    : eptw?.status === 'Approved' ? 'Approved' : 'Resubmitted'} />
        )
    }
    else return (
        <>
            <div className="mt-5">
                {eptw?.category !== "HIRAC" ?
                    <center>
                        <div className="card col-lg-7 col-sm-12 col-xs-12 col-md-12 text-left"
                            style={{ display: "block" }}
                        >
                            <Card sx={{ width: "100%" }}>
                                <form action="action" method='POST'
                                    onSubmit={handelSubmit}
                                >
                                    <div className="tab-content col-lg-12 col-sm-12" id="custom-tabs-two-tabContent">
                                        <div id="maintenance-tab" role="tabpanel" aria-labelledby="custom-tabs-two-maintenance-tab">
                                            <div className="col-lg-12 col-sm-12 pt-3">
                                                <div className="row">
                                                    <div className=" col-lg-6 col-md-6 col-sm-12">
                                                        <label className="font-weight-normal">Document Number</label>
                                                        <input disabled required name='meter_name' readOnly value={eptw?.document_number} type="text" className="form-control mb-2"
                                                            placeholder="Document Number"
                                                        />
                                                    </div>
                                                    <div className=" col-lg-6 col-md-6 col-sm-12">
                                                        <label className="font-weight-normal">Permit Number</label>
                                                        <input disabled required name='meter_name' readOnly value={eptw?.id} type="text" className="form-control mb-2"
                                                            placeholder="Permit Number"
                                                        />
                                                    </div>
                                                    <div className=" col-lg-6 col-md-6 col-sm-12">
                                                        <label className="font-weight-normal">Revision Number</label>
                                                        <input disabled required name='meter_name' readOnly value={eptw?.revision_number} type="text" className="form-control mb-2"
                                                            placeholder="Revision Number"
                                                        />
                                                    </div>
                                                    <div className=" col-lg-6 col-md-6 col-sm-12">
                                                        <label className="font-weight-normal">Revision Date</label>
                                                        <input disabled required name='date' readOnly value={date || ""} type="date" className="form-control mb-2"
                                                            placeholder="Revision Date"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body pb-3">

                                                {sections?.length > 0 && <div className="col-lg-12 col-sm-12 pt-3">
                                                    <div>
                                                        {sections.map((item, index) => (
                                                            <div className={classes.templateSection} key={index}>
                                                                <h3 className={classes.sectionTitle}>{item.title}</h3>
                                                                <ul className={classes.sectionFeilds}>
                                                                    {item?.fields?.map((ele, idx) => {
                                                                        const type = ele?.type
                                                                        return type === "String" || type === "Date" ? <div className="col-lg-12 col-sm-12">
                                                                            <label className="font-weight-normal">{ele.name}</label>{ele?.isRequired === true && <h6 style={{ marginTop: '30px', textAlign: 'center' }} className="font-danger fs-6 m-0">*Required</h6>}
                                                                            <input
                                                                                type={type === "String" ? "text" : "datetime-local"}                                                                                className="form-control mb-2"
                                                                                value={ele.value ? ele.value : ""}
                                                                                onChange={(e) => handelSections(e, ele, index, idx)}
                                                                            />
                                                                        </div> : type === "Checkbox" ? <div className="col-lg-12 col-sm-12">
                                                                            <div className={classes.templateCheckbox} style={{ mr: 0 }}>
                                                                                <input type="Checkbox"
                                                                                    checked={ele.value}
                                                                                    onChange={(e) => handelSections(e, ele, index, idx)} />
                                                                                <label className="font-weight-normal">{ele.name}</label>
                                                                            </div>
                                                                        </div> : type == 'Description' ? <div className="col-lg-12 col-sm-12"><h6>{ele.name}</h6></div> :
                                                                        type == 'Signature' ?
                                                                                <div className="col-lg-12 col-sm-12 pt-3">
                                                                                    <label className="font-weight-normal">{ele.name}{ele.isRequired === true && <h6 className="font-danger fs-6 m-0">*</h6>}</label>
                                                                                    <Signature required={ele} index={index} idx={idx} sections={sections} value={ele.value} />
                                                                                </div> : ""
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="col-lg-12 col-sm-12 pt-3">
                                                        <div className="row">
                                                            <div className=" col-lg-6 col-md-6 col-sm-12">
                                                                <label hidden className="font-weight-normal">Start Date  </label>
                                                                <input
                                                                    hidden
                                                                    onChange={(e) => handleStartDate(e)}
                                                                    type="date"
                                                                    className="form-control mb-2"
                                                                    name="service_date"
                                                                    id="meter_service_date"
                                                                    placeholder="Start Date"
                                                                    value={startDate}
                                                                />
                                                            </div>
                                                            <div className=" col-lg-6 col-md-6 col-sm-12">
                                                                <label hidden className="font-weight-normal">End Date  </label>
                                                                <input
                                                                    hidden
                                                                    onChange={(e) => handleEndDate(e)}
                                                                    type="date"
                                                                    className="form-control mb-2"
                                                                    name="service_date"
                                                                    id="meter_service_date"
                                                                    placeholder="End Date"
                                                                    value={endDate}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                            <center>
                                                <button type="submit" id='srvc-btn' className="btn primary-btn mb-5" > Submit </button>
                                            </center>
                                        </div>
                                    </div>
                                </form>
                            </Card>
                        </div>
                    </center> : <div style={{ padding: "30px" }}>
                        <table className={classes.table1}>
                            <thead>
                                <tr>
                                    <td>
                                        <img
                                            src={logo}
                                            alt=''
                                            className='brand-image img-company'
                                            id='result_company_base'
                                            height='100'
                                            width='100'
                                        />
                                    </td>
                                    <td >NORTHERN CORRIDOR IMPLEMENTATION AUTHORITY</td>
                                </tr>
                            </thead>
                        </table>
                        <table className={classes.table2}>
                            <thead>
                                <tr>
                                    <td >Hazard Identification Risk Assessment and Risk Control</td>
                                </tr>
                            </thead>
                        </table>
                        {hirarcSections.length > 0 && <> <table className={classes.table3}>
                            <tbody>
                                <tr>
                                    <td> Contractor Company Name <br />
                                        <input type="text"
                                            value={hirarcSections[0].fields[0].value}
                                            name={hirarcSections[0].fields[0].name}
                                            onChange={handelChangeOnMain}
                                        />
                                    </td>
                                    <td >Project Title <br />
                                        <input type="text"
                                            value={hirarcSections[0].fields[1].value}
                                            name={hirarcSections[0].fields[1].name}
                                            onChange={handelChangeOnMain}
                                        /></td>
                                    <td >Activity Assessed    <br />
                                        <input type="text"
                                            value={hirarcSections[0].fields[2].value}
                                            name={hirarcSections[0].fields[2].name}
                                            onChange={handelChangeOnMain} /></td>
                                    <td >PTW No   <br />
                                        <input type="text"
                                            value={hirarcSections[0].fields[3]?.value}
                                            name={hirarcSections[0].fields[3]?.name}
                                            onChange={handelChangeOnMain} />
                                    </td>
                                </tr>
                                <tr>
                                    <td >Conducted by  <br />
                                        <input type="text"
                                            value={hirarcSections[0].fields[4]?.value}
                                            name={hirarcSections[0].fields[4]?.name}
                                            onChange={handelChangeOnMain} /></td>
                                    <td>Date <br />
                                        <input type="datetime-local"
                                            value={hirarcSections[0].fields[5]?.value}
                                            name={hirarcSections[0].fields[5]?.name}
                                            onChange={handelChangeOnMain}
                                        /></td>
                                    <td >Approved by  <br />
                                         {/*<div style={{ display: "flex", justifyContent: "center" }}>
                                            {eptw?.approvals?.filter(item =>item.status === "Approved")?.map((item, index) => (
                                                <Avatar key={index} {...stringAvatar(item?.approver?.first_name || "", item?.approver?.last_name || "", item?.approver?.image)} title={item?.approver?.first_name || "contractor"} alt={item?.approver?.first_name || "contractor"} />))} 
                                            </div>*/}
                                        {eptw?.approvals?.filter(item =>item.status === "Approved")?.map((item) => (
                                        eptw?.approvals?.length > 1 ?
                                        <p>{item?.approver?.first_name} {item?.approver?.last_name}</p> :
                                        <input type="text" disabled value={`${item?.approver?.first_name} ${item?.approver?.last_name}`} />
                                    ))}
                                    </td>
                                    <td>Date <br />
                                        <input type="datetime-local"
                                            value={moment(eptw?.approved_date).format('YYYY-MM-DD')}
                                            disabled
                                        /></td>
                                </tr>
                            </tbody>
                        </table>

                            <table className={classes.table4}>
                                <thead>
                                    <tr>
                                        <td colSpan="4"> Hazard Identification  </td>
                                        <td colSpan="4">Risk Assessment </td>
                                        <td colSpan="1">Risk Control     </td>
                                    </tr>
                                    <tr>
                                        <td >  No.   </td>
                                        <td >Sequence of Activity/Task  </td>
                                        <td >Potential Hazard  </td>
                                        <td >Potential impact or Effects     </td>
                                        <td >Existing Control Measures      </td>
                                        <td >Severity      </td>
                                        <td >Likelihood      </td>
                                        <td >Risk Ranking       </td>
                                        <td >Recommended Control Measures </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {hirarcSections.map((item, index) => {
                                        if (item.title !== "main") {
                                            return <tr>
                                                <td>{item?.title}</td>
                                                {item?.fields?.map((ele, idx) => {
                                                    if (ele.type === "String") {
                                                        return <td ><input type="text"
                                                            name={ele.name}
                                                            value={ele.value}
                                                            onChange={(e) => handelHirarcChange(e, index, idx)} />
                                                        </td>
                                                    } else if (ele.name === "severity") {
                                                        return (<td >
                                                            <select name={ele.name}
                                                                value={ele.value}
                                                                style={{ fontSize: "16px", fontWeight: "normal", color: "black" }}
                                                                onChange={(e) => handelHirarcChange(e, index, idx)}>
                                                                <option value={1}>(1) Negligible (Minor abrasions, bruises, cuts, first aid type injury)    </option>
                                                                <option value={2}>(2) Minor (Disabling but not permanent injury)      </option>
                                                                <option value={3}>(3) Serious (Non-fatal injury, permanent disability)   </option>
                                                                <option value={4}>(4) Fatal (Approximately one single fatality major property damage if hazard is realized) .  </option>
                                                                <option value={5}>(5) Catastrophic (Numerous fatalities, irrecoverable property damage and productivity)      </option>
                                                            </select>
                                                        </td>
                                                        )
                                                    } else if (ele.name === "likelihood") {
                                                        return (<td>
                                                            <select name={ele.name}
                                                                value={ele.value}
                                                                style={{ fontSize: "16px", fontWeight: "normal", color: "black" }}
                                                                onChange={(e) => handelHirarcChange(e, index, idx)}>
                                                                <option value={1}>(1) Inconceivable (Is practically impossible and has never occurred)  </option>
                                                                <option value={2}>(2) Remote (Has not been known to occur after many years)     </option>
                                                                <option value={3}>(3) Conceivable (Might be occurring at some time in future)   </option>
                                                                <option value={4}>(4) Possible (Has a good chance of occurring and is not unusual)  </option>
                                                                <option value={5}>(5) Most likely (The most likely result of the hazard/event being realized)  </option>
                                                            </select>
                                                        </td>
                                                        )
                                                    } else if (ele.name === "risk_rating") {
                                                        return (<td
                                                            style={{
                                                                background: ele?.value <= 4 ? "green"
                                                                    : (ele?.value >= 5 && ele?.value <= 14) ? "yellow" : 'red'
                                                            }}>
                                                            {ele.value}
                                                        </td>
                                                        )
                                                    }
                                                })}
                                            </tr>
                                        }
                                    })}
                                </tbody>
                            </table>
                        </>}
                        <div style={{ display: "flex", justifyContent: "space-between" }} >
                            <div>
                                <button className='btn-primary btn mt-3' onClick={addNewRowHandeler}>Add New Row</button>
                                <button className='btn-primary btn mt-3 ml-2' disabled={hirarcSections.length === 1} onClick={removeRowHandeler}>Remove Last Row</button>
                            </div>
                            <button className="btn primary-btn mt-3"
                                onClick={handelSubmit}
                            >
                                Submit
                            </button>
                        </div>
                        <div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}
function MyVerticallyCenteredModal({
    showModal,
    handleClose,
    primaryButtonText,
    handleCreateSave,
    children,
    handleCreateTrim,
    clearButtonText,
    setTab,
    tab,
    setSignFile,
    setSignFilePhoto,
    setSign,
    setSignUrlImg,
}) {
    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal} onHide={handleClose}
        >
            <Modal.Header style={{ minWidth: "100%" }} closeButton>
                <Modal.Title style={{ width: "100%" }} id="contained-modal-title-vcenter">
                    {/* {modalTitle} */}
                    <div className='signature-header'>
                        <div onClick={() => {
                            setTab("sign")
                            setSignFile(null)
                            setSignFilePhoto(null)
                        }}>
                            <DrawIcon />
                            <p>Draw</p>
                        </div>
                    </div>
                </Modal.Title>
                <button
                    type='button'
                    className='close'
                    data-dismiss='modal'
                    aria-label='Close'
                    onClick={handleClose}
                >
                    <span aria-hidden='true'>&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer style={{ minWidth: "100%", justifyContent: "space-between" }} >
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                <div>
                    {tab === "sign" && <Button variant='primary' onClick={handleCreateTrim}>
                        {clearButtonText}
                    </Button>}
                    <span className='mr-2' />
                    <Button variant='primary' onClick={handleCreateSave}>
                        {primaryButtonText}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
export default ContractorView
