import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import InductionSuperVisorTable from '../../components/InductionSuperVisorTable/InductionSuperVisorTable'
import { getCompanyProfile } from '../../redux/features/company/companySlice';
import { Button, Modal } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  getContractorSessionDetailsPin,
  setPinCodeVerified
} from '../../redux/features/session/sessionSlice'
import InductionSystemTable from '../inductionSystemTable/inductionSystemTable'

const InductionSystemView = ({ data, isOpen, setIsOpen }) => {
  const { company, isLoading, isError, isSuccess } = useSelector(state => state.company);
  const backButtonStyle = {
    backgroundColor: '#f63854',
    color: 'white',
    '&:hover': {
      backgroundColor: '#f63854'
    }
  }
  const handlePrint = () => {
    window.print();
  };

  const { Disable } = data
  const { contractor_session_details_pin, isPinCodeVerified } = useSelector(
    state => state.session
  )
  const dispatch = useDispatch()
  const [pin, setPin] = useState('')
  const [hidePin, setHidePin] = useState(false)
  const [showResult, setShowResult] = useState(false)

  useEffect(() => {
    dispatch(setPinCodeVerified(false))
  })
  const handlePinChange = event => {
    const newPin = event.target.value
    setPin(newPin)
  }
  const Submit = () => {
    // dispatch(getContractorSessionDetailsPin(data))
    if (pin === Disable[1]) {
      const linkData = Disable[0].split('details/')[1].split('/')
      const data = {
        id: linkData[0],
        contractor_id: linkData[1],
        pin_code:btoa(Disable[1]),
        video_induction: Disable[2]
      }
      setShowResult(true)
      setHidePin(true)
      dispatch(getContractorSessionDetailsPin(data))
      dispatch(setPinCodeVerified(true))
    }
  }

  useEffect(() => {
    dispatch(getCompanyProfile)
  }, [contractor_session_details_pin])
  return (
    <Modal open={isOpen}>
      <Card sx={{ borderRadius: '12px', mt: 20,mr:10,ml:10 }}>
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <img
            src={company?.logo||'/FOX-Logo3.jpeg'}
            alt='Company Logo'
            style={{
              maxWidth: '100px'
            }}
          />
          <Grid
            item
            xs={3}
            style={{
              marginTop: '8px'
            }}
          >
            <Button
              style={backButtonStyle}
              sx={{ mr: 2 }}
              variant='contained'
              onClick={() => {
                setPin('')
                setShowResult(false)
                setHidePin(false)
                setIsOpen(false)
              }}
            >
              Close
            </Button>
            <Button
              style={backButtonStyle}
              sx={{ mr: 2 }}
              variant='contained'
              onClick={
                handlePrint
              }
            >
              Print
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          style={{ height: '100%' }}
        >

            <Grid >
              <CardContent >
                <Grid
                  container
                  spacing={8}
                  justifyContent='center'
                  alignItems='center'
                >
                  <Grid item xs={6}>
                    <h4>Company Name: {data.company}</h4>
                    <h4>Induction ID: {data.id}</h4>
                  </Grid>
                  <Grid item xs={6}>
                    <h4>
                      Date: {new Date(data.expiry_date).toLocaleDateString()}
                    </h4>
                    <h4>Filled by: {data.filled_by} </h4>
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
                <Grid item xs={12} >
                  <InductionSystemTable
                   logo={company?.logo} id={data.id} />
                </Grid>
                {/* <Grid item xs={12} >
                  <InductionSuperVisorTable
                   logo={company?.logo} filledBy={contractor_session_details_pin} />
                </Grid> */}

              </CardContent>
            </Grid>
        </Grid>
      </Card>
    </Modal>
  )
}

export default InductionSystemView
