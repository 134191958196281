import React from 'react';

import Container from '@mui/material/Container';

import Paper from '@mui/material/Paper';

import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';

import DangerousIcon from '@mui/icons-material/Dangerous';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const containerStyle = {

    display: 'flex',

    alignItems: 'center',

    justifyContent: 'center',

    minHeight: '100vh',

};

const paperStyle = {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '800px',

};



const iconContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f63854',
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    marginBottom: '16px',
};



const iconStyle = {
    fontSize: '48px',
    color: 'white',
};



const messageStyle = {
    textAlign: 'center',
    marginBottom: '16px',
};



const backButtonStyle = {
    backgroundColor: '#f63854',
    color: 'white',
    '&:hover': {
        backgroundColor: '#f63854',
    },
};



const FaileInduction = (props) => {
    const history = useHistory();
    return (
        
        // <Container style={containerStyle}>

            <Paper style={paperStyle} elevation={3}>

                <div style={iconContainerStyle}>

                    <DangerousIcon style={iconStyle} />

                </div>

                <Typography variant="h5" style={messageStyle}>

                    Result :
                </Typography>

                <Typography variant="h5" style={messageStyle}>

                    {/* Your Score: {(props.location.state.correctAnswerCount)/(props.location.state.wrongAnswerCount+props.location.state.correctAnswerCount)*100}% {(props.location.state.correctAnswerCount+props.location.state.wrongAnswerCount)} questions */}
                </Typography>

                <Typography variant="h5" style={messageStyle}>

                    {/* Passing Score: 50% ({(props.location.state.correctAnswerCount+props.location.state.wrongAnswerCount)/2} correct answer(s)) */}
                </Typography>
                <Button

                    variant="contained"

                    style={backButtonStyle}

                    onClick={() => {

                       history.push(props?.location?.state?.inductionLink,props?.location?.state?.sessionData)
                    }
                }

                >

                    Try Again
                </Button>

            </Paper>

        // </Container>

    );

};



export default FaileInduction;