import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import classes from '../../../styles/asset.module.css'
import { CardContent, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import foxSafety from '../../../assets/img/assessment.jpg'
import { useDispatch, useSelector } from 'react-redux'

const InductionModalViewOnlyComponent = () => {
  const [customSection, setCustomSection] = useState([
    { title: '', feilds: [] }
  ])
  const dispatch = useDispatch()
  const { session } = useSelector(state => state.session)
  useEffect(() => {
    // const template = sessions.find(item => item?.id === session_id)
    // const sections = [];
    const sections = session?.video?.quizes[0]?.questions || []
    if (sections.length === 0) return
    const newArray = []
    for (const sectionNames of sections) {
      const section = {
        title: sectionNames.question,
        feilds: [
          {
            name: sectionNames.answer1,
            type: sectionNames.correct_answer === 1 ? 'Right' : 'Wrong'
          },
          {
            name: sectionNames.answer2,
            type: sectionNames.correct_answer === 2 ? 'Right' : 'Wrong'
          },
          {
            name: sectionNames.answer3,
            type: sectionNames.correct_answer === 3 ? 'Right' : 'Wrong'
          },
          {
            name: sectionNames.answer4,
            type: sectionNames.correct_answer === 4 ? 'Right' : 'Wrong'
          }
        ]
      }
      newArray.push(section)
    }
    setCustomSection(newArray)
  }, [session])
  return (
    <Box
      sx={{
        padding: 5,
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center'
      }}
    >
      <section>
        <Box
          sx={{
            bgcolor: 'white',
            borderRadius: '20px',
            flexGrow: 1,
            padding: 3
          }}
        >
          <Grid container spacing={6}>
            <Grid item xs={12} md={4}>
              <div>
                <img src={foxSafety} alt='' />
              </div>
            </Grid>
            <Grid item xs={12} md={8}>
              <div>
                <h2>Welcome to safety induction session creation page</h2>
                <p>
                  Use FOX safety induction sessions can be created in few steps.
                </p>
                {/* <Button
                  sx={{ mt: 4 }}
                  style={backButtonStyle}
                  onClick={e => history.push('/safetyDashBoard')}
                >
                  Safety Dashboard
                </Button> */}
              </div>
            </Grid>
          </Grid>
          <Box>
            <section>
              <Box>
                <Typography
                  alignContent={'center'}
                  variant='h5'
                  component='div'
                >
                  Session Name
                </Typography>
                <TextField
                  required
                  fullWidth
                  value={session?.title}
                  sx={{ mt: 1 }}
                  id='session-name'
                  // label='Session Name'
                  type='text'
                />
              </Box>
              <div
                style={{
                  height: '400px',
                  overflowY: 'auto',
                  marginTop: '20px'
                }}
              >
                <h3 className={classes.templateName}>Quiz Name : {session?.video?.quizes[0]?.title}</h3>
                {customSection.map((item, index) => (
                  <div className={classes.templateSection} key={index}>
                    <h3 className={classes.sectionTitle}>
                      Question {index + 1} : {item.title}
                    </h3>
                    <p>List of answer</p>
                    <ul className={classes.sectionFeilds}>
                      {item.feilds.map((ele, idx) => {
                        const type = ele.type
                        return ele.name && (type !== 'Checkbox' ? (
                          <div className='col-lg-12 col-sm-12'>
                            <label className='font-weight-normal'>
                              {idx + 1} ) {ele.name}
                            </label>
                            <input
                              type={
                                type === 'Right'
                                  ? 'Right Answer'
                                  : 'Wrong Answer'
                              }
                              className='form-control mb-2'
                              disabled
                              placeholder={ele.type}
                            />
                          </div>
                        ) : (
                          <div className='col-lg-12 col-sm-12'>
                            <div className={classes.templateCheckbox}>
                              <input type='checkbox' disabled />
                              <label className='font-weight-normal'>
                                {ele.name}
                              </label>
                            </div>{' '}
                          </div>
                        ))
                      })}
                    </ul>
                  </div>
                ))}
              </div>
              <CardContent>
                
                <h4 style={{ textAlign: 'center' }}>{session?.video?.title}</h4>
                <div style={{ position: 'relative', paddingTop: '56.25%' }}>
                  <iframe
                    title='Embedded YouTube Video'
                    src={session?.video?.link}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%'
                    }}
                    frameBorder='0'
                  ></iframe>
                </div>
              </CardContent>
            </section>
          </Box>
        </Box>
      </section>
    </Box>
  )
}

export default InductionModalViewOnlyComponent
